import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';

import Layout from '../components/layout';
import { OrderContext, OrderDispatch } from '../components/order-context';
import SEO from '../components/seo';
import Button from '../components/button';

const CheckoutForm = props => {
  const { orders, totalAmount } = props;
  const formRef = useRef();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.stripe.com/checkout.js';
    script.async = true;
    formRef.current.appendChild(script);
  });

  const totalAmountWithCents = totalAmount * 100;

  // The stripe Checkout form will not update the price if it simply changes with react props.
  // The stripe component needs to be forcibly unmounted and remounted to update the price.
  // This is achieved by settings the key of the form as the total amount. Changing the amount
  // will change the key of the component forcing a complete rerender.
  return (
    <form
      key={totalAmountWithCents}
      ref={formRef}
      action="https://5lh9bwddlh.execute-api.ap-southeast-2.amazonaws.com/dev"
      method="POST"
    >
      <input type="hidden" name="orderlines" value={JSON.stringify(orders)} />
      <script
        src="https://checkout.stripe.com/checkout.js"
        className="stripe-button"
        data-key="pk_test_SV0CO77EyDRsYreOjXMtL2DM"
        data-amount={totalAmountWithCents}
        data-name="Banana Park"
        data-description="Your Phonecase Order"
        data-image="https://stripe.com/img/documentation/checkout/marketplace.png"
        data-locale="auto"
        data-currency="aud"
        data-zip-code="true"
        data-billing-address="true"
      />
    </form>
  );
};

const Order = () => {
  const orders = useContext(OrderContext);
  const ordersDispatch = useContext(OrderDispatch);

  return (
    <Layout>
      <SEO title="Order" />
      {orders && orders.length ? (
        <Orderlines orders={orders} ordersDispatch={ordersDispatch} />
      ) : (
        <div>
          You don't have any orders yet.
          <Link to="/custom-phone-case/">Get customising!</Link>
        </div>
      )}
    </Layout>
  );
};

const OrderTotal = props => (
  <div
    css={css`
      display: inline-grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: 30px;
      column-gap: 20px;
      justify-items: end;
      padding: 10px;
    `}
  >
    <div
      css={css`
        padding-top: 3px;
      `}
    >
      <strong>Total: </strong>${props.totalAmount}
    </div>
    <CheckoutForm orders={props.orders} totalAmount={props.totalAmount} />
  </div>
);

const Orderlines = props => {
  const { orders, ordersDispatch } = props;
  let totalAmount = 0;
  orders.forEach(
    orderline => (totalAmount += orderline.quantity * orderline.price)
  );
  return (
    <>
      <div
        css={css`
          max-width: 600px;
          margin: auto;
        `}
      >
        <div
          css={css`
            display: grid;
            justify-contents: space-between;
            grid-template-columns: auto auto;
          `}
        >
          <h1
            css={css`
              display: inline-block;
              margin-bottom: 5px;
            `}
          >
            Your Order
          </h1>
          <OrderTotal totalAmount={totalAmount} orders={orders} />
        </div>
        <div>
          {orders.map(orderline => (
            <React.Fragment key={`orderline_${orderline.id}`}>
              <div
                css={css`
                  background-color: #fff6ff;
                  display: grid;
                  grid-template-columns: auto 1fr;
                  padding: 20px;

                  &:first-child {
                    border-top-left-radius: 25px;
                    border-top-right-radius: 25px;
                  }
                  &:last-child {
                    border-bottom-left-radius: 25px;
                    border-bottom-right-radius: 25px;
                  }
                  &:nth-child(odd) {
                    background-color: #f3f6ff;
                  }
                `}
              >
                <div>
                  <img
                    alt="Your order"
                    css={css`
                      max-width: 100px;
                      border: solid 4px #111;
                      border-radius: 25px;
                      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
                      @media only screen and (min-width: 480px) {
                        max-width: 200px;
                      }
                    `}
                    src={orderline.thumbnail}
                  />
                </div>
                <div
                  css={css`
                    padding: 20px;
                    position: relative;
                  `}
                >
                  <Button
                    cssExtras="position: absolute; top: 0px; right: 0px; width: 45px; padding: initial; background-color: transparent;"
                    inverse={true}
                    onClick={() =>
                      ordersDispatch({ type: 'delete', value: orderline.id })
                    }
                  >
                    X
                  </Button>
                  <div
                    css={css`
                      margin-top: 50px;
                      margin-right: 50px;
                      text-align: right;
                      div {
                        margin-bottom: 10px;
                      }
                    `}
                  >
                    <div
                      css={css`
                        font-weight: bold;
                      `}
                    >
                      {orderline.name}
                    </div>
                    <div>{orderline.product}</div>
                    <div>${orderline.price}</div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div
          css={css`
            text-align: right;
          `}
        >
          <OrderTotal totalAmount={totalAmount} orders={orders} />
        </div>
      </div>
    </>
  );
};

export default Order;
